import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const BlogTemplate = ({ data, pageContext }) => {
  const { html } = data.markdownRemark
  const { title, author, date, featuredImg } = data.markdownRemark.frontmatter
  return (
    <Layout>
      <SEO title={pageContext.slug} description={pageContext.description} />
      <main className="page blog-page">
        <h1><strong>{title}</strong></h1>
        <p>datum: {date}</p>
        <p>autor: {author}</p>
        <div className="tagImg">
        <GatsbyImage
        image={getImage(featuredImg.childImageSharp.gatsbyImageData)}
         />
        </div>
        <div className="pageText" dangerouslySetInnerHTML={{ __html: html }} />
        <div className="backBtn">
              <Link to="/blog/#blogs"  className="btn">
                &laquo; Povratak 
              </Link>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql` 
query BlogTemplate($slug: String) {
  markdownRemark(frontmatter: {slug: {eq: $slug}}) {
    html
    frontmatter {
      slug
      author
      date
      description
      title
      featuredImg {
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            transformOptions: {fit: CONTAIN}
            width: 800
          )
        }
      }
    }
  }
}
`


export default BlogTemplate
